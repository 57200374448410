import "./App.css";

const asciiArt = String.raw`
$$\   $$\ $$$$$$$\         $$$$$$\  $$\   $$\ $$\   $$\     $$\ 
$$ |  $$ |$$  __$$\       $$  __$$\ $$$\  $$ |$$ |  \$$\   $$  |
$$ |  $$ |$$ |  $$ |      $$ /  $$ |$$$$\ $$ |$$ |   \$$\ $$  / 
$$ |  $$ |$$$$$$$  |      $$ |  $$ |$$ $$\$$ |$$ |    \$$$$  /  
$$ |  $$ |$$  ____/       $$ |  $$ |$$ \$$$$ |$$ |     \$$  /   
$$ |  $$ |$$ |            $$ |  $$ |$$ |\$$$ |$$ |      $$ |    
\$$$$$$  |$$ |             $$$$$$  |$$ | \$$ |$$$$$$$$\ $$ |    
 \______/ \__|             \______/ \__|  \__|\________|\__|    
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <pre className="asciiArt">{asciiArt}</pre>
        <br />
        <small>&middot; for art lovers and degens only &middot;</small>
        <br />
        <div className="App-header-links">
          <a
            href="https://etherscan.io/address/0x7CE67a78D957e92A51d88673aFA261E87891FaE7"
            target="_blank"
            rel="noopener noreferrer"
          >
            contract
          </a>{" "}
          &middot;{" "}
          <a
            href="https://opensea.io/collection/up-only-art"
            target="_blank"
            rel="noopener noreferrer"
          >
            opensea
          </a>
          <br />
          <br />
          <span className="project-info">
            an{" "}
            <a href="https://x3labs.io/" target="_blank" rel="noreferrer">
              x<sup>3</sup> labs
            </a>{" "}
            project
          </span>
        </div>
      </header>
    </div>
  );
}

export default App;
